import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const EnquiryForm = () => {
  const [status, setStatus] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(''); // State for phone number
  const [errors, setErrors] = useState({}); // State for tracking validation errors
  const form = useRef();

  // Validation function
  const validateForm = () => {
    const name = form.current.user_name.value.trim();
    const email = form.current.user_email.value.trim();
    const phone = phoneNumber.trim();
    const message = form.current.user_message.value.trim();
    const newErrors = {}; // Object to hold errors for each field

    // Check if any field is empty
    if (!name) newErrors.name = 'Name is required.';
    if (!email) newErrors.email = 'Email is required.';
    if (!phone) newErrors.phone = 'Phone number is required.';
    if (!message) newErrors.message = 'Message is required.';

    // Optional: Add additional validation for the email format
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (email && !emailPattern.test(email)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    setErrors(newErrors); // Update the errors state
    return Object.keys(newErrors).length === 0; // Return true if no errors, otherwise false
  };

  // Handle input change event to remove error
  const handleInputChange = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field]; // Remove the specific field error
      return newErrors;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) return;

    // Format the phone number by adding the '+' symbol
    const formattedPhoneNumber = `+${phoneNumber}`;

    // Prepare email payload with the form data
    const payload = {
      user_name: form.current.user_name.value,
      user_email: form.current.user_email.value,
      user_phone: formattedPhoneNumber, // Send the formatted phone number
      user_message: form.current.user_message.value,
    };

    // Send the email using EmailJS
    emailjs
      .send('service_ek2eyoi', 'template_lsosqfu', payload, 'TL7p-oDPG8opFE6tC')
      .then(
        (response) => {
          setStatus('Your enquiry has been sent successfully!');
          console.log('SUCCESS:', response);
          form.current.reset(); // Reset the form on success
          setPhoneNumber(''); // Reset the phone number state
          setErrors({}); // Clear errors on success
        },
        (err) => {
          setStatus('Failed to send enquiry. Please try again.');
          console.error('FAILED:', err);
        }
      );
  };

  // Utility function to determine if a field has an error
  const getFieldClass = (field) => {
    return errors[field] ? 'form-control is-invalid' : 'form-control';
  };

  return (
    <div>
      <form ref={form} onSubmit={handleSubmit} className="appoitment-form ajax-contact">
        <div className="row">
          <div className="form-group col-12">
            <input
              type="text"
              className={getFieldClass('name')} // Apply error class if needed
              name="user_name" // Corresponds to the variable in the EmailJS template
              id="name"
              placeholder="Enter Your Name"
              onChange={() => handleInputChange('name')} // Remove error on input change
            />
            {errors.name && <div className="invalid-feedback">{errors.name}</div>} {/* Show error message */}
          </div>
          <div className="form-group col-12">
            <input
              type="email"
              className={getFieldClass('email')} // Apply error class if needed
              name="user_email" // Corresponds to the variable in the EmailJS template
              id="email"
              placeholder="Enter Your Email"
              onChange={() => handleInputChange('email')} // Remove error on input change
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>} {/* Show error message */}
          </div>
          <div className="form-group col-12">
            <PhoneInput
                country={'in'}
                value={phoneNumber}
                onChange={(value) => {
                    setPhoneNumber(value); // Update the phone number state
                    handleInputChange('phone'); // Remove error on input change
                }}
                name="user_phone" // Ensure this matches the variable in the EmailJS template
                id="number"
                placeholder="Enter Your Phone Number"
                className={errors.phone ? 'form-control is-invalid' : 'form-control'} // Apply error class if needed
            />

            {errors.phone && <div className="invalid-feedback">{errors.phone}</div>} {/* Show error message */}
          </div>
          <div className="form-group col-12">
            <textarea
              name="user_message" // Corresponds to the variable in the EmailJS template
              id="message"
              cols="30"
              rows="3"
              className={getFieldClass('message')} // Apply error class if needed
              placeholder="Write Your Message"
              onChange={() => handleInputChange('message')} // Remove error on input change
            ></textarea>
            {errors.message && <div className="invalid-feedback">{errors.message}</div>} {/* Show error message */}
          </div>
          <div className="form-btn col-12">
            <button type="submit" className="th-btn w-100">
              SEND A MESSAGE
            </button>
          </div>
        </div>
        <p className="form-messages mb-0 mt-3">{status}</p>
      </form>
    </div>
  );
};

export default EnquiryForm;
