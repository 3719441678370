import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import './css/FrontEnd.css';

const FrontEnd = (props) => {
  return (
    <>
        <Header />
        <Outlet />
        <Footer />

        <div className="contact-strips-1">
          {/* WhatsApp Strip */}
          <div className="contact-strip whatsapp-strip">
            <div className="contact-icons">
              {/* WhatsApp Icon */}
              <a href="https://wa.me/918693085077" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-whatsapp fa-beat icon"></i>
              </a>
              <p className="contact-detail"><strong>+91 8693085077</strong></p> {/* WhatsApp Number */}
            </div>
          </div>
        </div>
        <div className="contact-strips-2">
          {/* Email Strip */}
          <div className="contact-strip email-strip">
            <div className="contact-icons">
              {/* Email Icon */}
              <a href="mailto:info@warrgyizmorsch.com" target="_blank" rel="noopener noreferrer">
                <i className="fa-thin fa-envelope fa-beat icon"></i>
              </a>
              <p className="contact-detail"><strong>info@warrgyizmorsch.com</strong></p> {/* Email */}
            </div>
          </div>
        </div>
        <div className="contact-strips-3">
          {/* Contact Number Strip */}
          <div className="contact-strip number-strip">
            <div className="contact-icons">
              {/* Phone Icon */}
              <a href="tel:+918693085077" target="_blank" rel="noopener noreferrer">
                <i className="fa-thin fa-phone fa-beat icon"></i>
              </a>
              <p className="contact-detail"><strong>+91 8693085077</strong></p> {/* Phone Number */}
            </div>
          </div>
        </div>
    </>
  )
};

export default FrontEnd;