import { useEffect } from "react";

const useMetaTags = ({ title, description, keywords }) => {
  useEffect(() => {
    // Set document title only if title exists, otherwise set an empty title
    document.title = title || "";

    // Function to update or remove meta tag
    const updateMetaTag = (name, content) => {
      let metaTag = document.querySelector(`meta[name='${name}']`);
      if (content) {
        if (!metaTag) {
          metaTag = document.createElement("meta");
          metaTag.name = name;
          document.head.appendChild(metaTag);
        }
        metaTag.content = content;
      } else if (metaTag) {
        metaTag.remove();
      }
    };

    // Update meta tags, or remove them if values are empty
    updateMetaTag("description", description || "");
    updateMetaTag("keywords", keywords || "");

    // Get the current full URL dynamically
    const canonicalUrl = window.location.href;

    // Update or create canonical link
    let linkCanonical = document.querySelector("link[rel='canonical']");
    if (!linkCanonical) {
      linkCanonical = document.createElement("link");
      linkCanonical.rel = "canonical";
      document.head.appendChild(linkCanonical);
    }
    linkCanonical.href = canonicalUrl;

  }, [title, description, keywords]);

};

export default useMetaTags;
