import React from 'react'
import ProductSlider from '../component/ProductSlider';
import useMetaTags from '../hooks/useMetaTags';
import EnquiryForm from '../component/EnquiryForm';

const Test = () => {

    useMetaTags({
        title: "A Complete IT Company for Digital Success",
        description: "warrgyizmorsch specializes in it software development company UK, Digital marketing, and app services, business transformation us to bring your online presence to the next level.",
    });
    
    
    
    
// AboutSlider-start-------------------------------
    const aboutSlider = [
        {
        title: "Our Services",
        description: "Simplified hiring solutions for your everyday business needs! Ranging from virtual assistants, business process, web and mobile app services.",
        link: "/services",
        btnText: "Explore More"
        },
        {
        title: "Vision Mission & Values",
        description: "Our mission, vision and values are the core foundation of our success and commitment to excellence.",
        link: "/about-us",
        btnText: "Explore More"
        },
        {
        title: "Security Measures",
        description: "Our network and security department help protect client data and provide security against common IT risks.",
        link: "/about-us",
        btnText: "Explore More"
        }
    ];
  

    const generateCardHTMLForAboutSlider = (cardContent) => {

        const title = cardContent.title;
        const description = cardContent.description;
    
        return `      
            <div class="title-area mb-40">
                <span class="sub-title style1 text-white">${title}</span>
                <h5 class="sec-title text-white">${description}</h5>
            </div>
            <a href="${cardContent.link}" class="th-btn style5 style-radius">${cardContent.btnText}</a>
        `;
    };
  
    const generateSliderHTMLForAboutSlider = (data) => {
        return data.map(generateCardHTMLForAboutSlider);
    };

    const cardHTMLDataForAboutSlider = generateSliderHTMLForAboutSlider(aboutSlider);
// AboutSlider-end-------------------------------

  
// ServiceSlider-start-------------------------------
    const serviceCardData = [
        // {
        //   imgSrc: "assets/img/icon/csp.avif",
        //   title: "Voice Support",
        //   description: "When you're looking to make your business stand out, voice assistance services add the missing feature...",
        //   link: "/call-preference-service",
        // },
        // {
        //   imgSrc: "assets/img/icon/omni.jpg",
        //   title: "Omni Support",
        //   description: "In German it is 'der Kunde ist König' (customer is king) or in Japanese, it is 'okyakusama wa kamisama desu'...",
        //   link: "/omni-support",
        // },
        {
          imgSrc: "assets/img/icon/virtual.jpg",
          title: "Virtual Learning",
          description: "In the digital age, doing mundane tasks within the same organization is now rare. It is difficult to manage all aspects...",
          link: "/virtual-learning-services",
        },
        {
          imgSrc: "assets/img/icon/accountandbook.jpg",
          title: "Accounting and Book Keeping",
          description: "Finance accounting and bookkeeping services, is extremely important aspect for any business and certainly requires time...",
          link: "/accounting-bookkeeping-services-uk",
        },
        {
          imgSrc: "assets/img/icon/e-commerce.avif",
          title: "E Commerce services",
          description: "Maintaining a stable sales volume is the primary goal of an online seller. However, it is difficult to achieve this unless...",
          link: "/e-commerce-services",
        },
        {
          imgSrc: "assets/img/icon/data-manage.avif",
          title: "Data management",
          description: "To be an efficient fuel source for businesses, data must be managed efficiently. Data management services are...",
          link: "/data-management-services",
        },
        {
          imgSrc: "assets/img/icon/website.avif",
          title: "Website Design And Development",
          description: "A web design application development company that helps businesses build strong customer profiles and reaches potential...",
          link: "/website-design-development",
        },
        {
          imgSrc: "assets/img/icon/content.avif",
          title: "Content Writing",
          description: "Content writing is all about drafting thoughts and business ideas on paper. It is the process of planning, researching, ...",
          link: "/content-writing-service",
        },
        {
          imgSrc: "assets/img/icon/service_3d_3.png",
          title: "Digital Marketing",
          description: "Intrinsicly redefine competitive e-business before adaptive potentialiti. Professionally build progressive users with...",
          link: "/digital-marketing-services",
        },
        {
          imgSrc: "assets/img/icon/graphic-designer.jpg",
          title: "Graphic Design",
          description: "Our expert offers graphic design services with cover branding, logo creation, UI/UX design, and promotional materials...",
          link: "/graphic-design",
        },
        {
          imgSrc: "assets/img/icon/mobile app.jpg",
          title: "Mobile Apps",
          description: "With custom mobile app development for iOS and Android. We aim to provide a smooth user experience with new advanced features...",
          link: "/mobile-app",
        },
        {
          imgSrc: "assets/img/icon/software.jpg",
          title: "Software Development",
          description: "Custom software development solutions for businesses. It includes enterprise-level applications, SaaS development, automation tools...",
          link: "/software-development",
        }
    ];
      
    const generateCardHTML = (cardContent) => {
    
        const truncateText = (text, length) => {
        return text.length > length ? text.substring(0, length) + '...' : text;
        };
    
        const title = truncateText(cardContent.title, 16); 
        const description = truncateText(cardContent.description, 100); 
    
        return `
        <div class="swiper-slide">
            <div class="service-3d">
                <div class="service-3d_icon">
                    <img loading="lazy" style="width: auto; height: 150px; object-fit: cover;" src="${cardContent.imgSrc}" alt="${cardContent.title}Icon" title="${cardContent.title}"  >
                </div>
                <div class="service-3d_content">
                    <h3 style="" class="box-title">${title}</h3>
                    <p style="max-height: 80px; overflow: hidden;" class="service-3d_text">${description}</p>
                    <a href="${cardContent.link}" class="th-btn">
                    Read More
                    <i class="fas fa-arrow-right ms-2"></i>
                    </a>
                </div>
            </div>
        </div>
        `;
    };
      
    const generateSliderHTML = (data) => {
        return data.map(generateCardHTML);
    };
    
    const cardHTMLDataForService = generateSliderHTML(serviceCardData);
      
// ServiceSlider-end-------------------------------

// TestimonialSlider-start-------------------------------

    const cardData4 = [
        {
        reviewStars: 5,
        text: "Phosfluorescently reinvent prospective metrics before granular schema. Professionally metrics before expedite client-centric methods of empow ment whereas effective solut ion.",
        avatarSrc: "assets/img/testimonial/testi_1_1.jpg",
        name: "Alex Farnandes",
        designation: "UI/UX Designer",
        quoteIconSrc: "assets/img/icon/quote_left.svg"
        },
        {
        reviewStars: 5,
        text: "Dnosflu orescent reinvent prospective metrics before granular schema. Professionally metrics before expedite client-centric methods of empow ment whereas effective coverless.",
        avatarSrc: "assets/img/testimonial/testi_1_2.jpg",
        name: "Mariana Sonia",
        designation: "CEO at Corola",
        quoteIconSrc: "assets/img/icon/quote_left.svg"
        },
        {
        reviewStars: 5,
        text: "The best service reinvent prospective metrics before granular schema. Professionally metrics before expedite client-centric methods of empow ment whereas effective turnings.",
        avatarSrc: "assets/img/testimonial/testi_1_3.jpg",
        name: "Abraham Khalil",
        designation: "UI/UX Designer",
        quoteIconSrc: "assets/img/icon/quote_left.svg"
        },
        {
        reviewStars: 5,
        text: "Five star he reinvent prospective metrics before granular schema. Professionally metrics before expedite client-centric methods of empow ment whereas effective gotoning.",
        avatarSrc: "assets/img/testimonial/testi_1_4.jpg",
        name: "Alexa Jenifer",
        designation: "CEO at Morosto",
        quoteIconSrc: "assets/img/icon/quote_left.svg"
        }
    ];
  
  
    const generateTestimonialCardHTML = (cardContent) => {
        const truncateText = (text, length) => {
            return text.length > length ? text.substring(0, length) + '...' : text;
        };
        const cardContentText = truncateText(cardContent.text, 150); 
        const cardContentName = truncateText(cardContent.name, 20); 
        const cardContentDesignation = truncateText(cardContent.designation, 20);
        const stars = Array(cardContent.reviewStars).fill('<i class="fa-solid fa-star-sharp"></i>').join('');
    
        return `
        <div class="swiper-slide">
            <div class="testi-card">
                <div class="testi-card_review">
                    ${stars}
                </div>
                <p class="testi-card_text">
                    "${cardContentText}"
                </p>
                <div class="testi-card_profile">
                    <div class="testi-card_avater">
                    <img loading="lazy" src="${cardContent.avatarSrc}" alt="${cardContent.name}" title="${cardContent.name}">
                    </div>
                    <div class="media-body">
                    <h3 class="testi-card_name">${cardContentName}</h3>
                    <span class="testi-card_desig">${cardContentDesignation}</span>
                    </div>
                </div>
                <div class="testi-card_quote">
                    <img loading="lazy" src="${cardContent.quoteIconSrc}" alt="quote${cardContent.name}" title="${cardContent.name}">
                </div>
            </div>
        </div>
        `;
    };
  
    const generateTestimonialSliderHTML = (data) => {
        return data.map(generateTestimonialCardHTML);
    };
    
    const cardHTMLDataForTestimonials = generateTestimonialSliderHTML(cardData4);
    
  
// TestimonialSlider-end-------------------------------
          
//blog-start

    const blogDataList = [
        {
            imageSrc: "assets/img/blog/blog_1.jpg",
            altText: "blog image 1",
            date: "15 Jan, 2022",
            comments: 2,
            title: "How digital marketing is different from traditional marketing?",
            description: "Traditional marketing is considered when marketing activities for an organization are undertaken in a conventional way such as by television.",
            blogLink: "/how-digital-marketing-is-different-from-traditional-marketing"
        },
        {
            imageSrc: "assets/img/blog/blog_2.jpg",
            altText: "blog image 2",
            date: "15 Jan, 2022",
            comments: 2,
            title: "How digital marketing helps in business growth?",
            description: "With the continuous changing of working culture globally, Digital marketing has taken the place of conventional marketing. With the great evolution of modern technology, small and medium businesses...",
            blogLink: "/how-digital-marketing-helps-in-business-growth"
        },
        {
            imageSrc: "assets/img/blog/blog_3.jpg",
            altText: "blog image 3",
            date: "15 Jan, 2022",
            comments: 2,
            title: "Top Digital Marketing Strategies for gym",
            description: "Nowadays, when people do not have much time for themselves due to the hustle in their lives, they face many health-related issues...",
            blogLink: "/top-digital-marketing-strategies-for-gym"
        },
        {
            imageSrc: "assets/img/blog/blog_4.jpg",
            altText: "blog image 4",
            date: "15 Jan, 2022",
            comments: 2,
            title: "Top 10 Digital Marketing Strategies for the startups",
            description: "It is very difficult to persist in the market and establish your startups when competition is quite high in every business...",
            blogLink: "/top-10-digital-marketing-strategies-for-the-startups"
        },
        {
            imageSrc: "assets/img/blog/blog_5.jpg",
            altText: "blog image 5",
            date: "15 Jan, 2022",
            comments: 2,
            title: "Top Digital Marketing Strategies for product launch",
            description: "We all eagerly await product launch programs. After putting in much attention and hard work, the final day arrives...",
            blogLink: "/top-digital-marketing-strategies-for-the-product-launch"
        },
        {
            imageSrc: "assets/img/blog/blog_6.jpg",
            altText: "blog image 6",
            date: "15 Jan, 2022",
            comments: 2,
            title: "How does Digital Marketing Help Small Businesses?",
            description: "It's a fact that good marketing plays a crucial role in growing a business. It can enhance your business and boost confidence...",
            blogLink: "/how-does-digital-marketing-help-small-businesses"
        }
    ];
    // Function to truncate text with ellipsis
    const truncateText = (text, length) => {
        return text.length > length ? text.substring(0, length) + '...' : text;
    };

    const generateBlogCardHTML = (blogData) => {
        // Define default text lengths
        let titleLength = 35;
        let descLength = 105;

        // Adjust text lengths based on window width
        if (window.innerWidth < 265) {
            titleLength = 20;
            descLength = 50;
        } else if (window.innerWidth > 265 && window.innerWidth < 355) {
            titleLength = 20;
            descLength = 75;
        } else if (window.innerWidth > 355 && window.innerWidth < 387) {
            titleLength = 25;
            descLength = 85;
        } else if (window.innerWidth > 387 && window.innerWidth < 450) {
            titleLength = 20;
            descLength = 90;
        }else if (window.innerWidth > 450 && window.innerWidth < 768) {
            titleLength = 25;
            descLength = 90;
        }else if (window.innerWidth > 768 && window.innerWidth < 1300) {
            titleLength = 30;
            descLength = 100;
        }
        const title = truncateText(blogData.title, titleLength);
        const description = truncateText(blogData.description, descLength);

        return `
        <div class="swiper-slide">
            <div class="blog-card">
                <div class="blog-img">
                    <img loading="lazy" style="width: 100%; height: 200px; object-fit: cover;" src="${blogData.imageSrc}" alt="${blogData.altText}" title="${blogData.title}">
                </div>
                <div class="blog-content">
                    <div class="blog-meta">
                        <a href="javascript:void(0);">
                            <i class="fal fa-calendar-days"></i>
                            ${blogData.date}
                        </a>
                        <a href="javascript:void(0);">
                            <i class="fal fa-comments"></i>
                            ${blogData.comments} Comments
                        </a>
                    </div>
                    <h3 class="box-title">
                        <a href="${blogData.blogLink}">
                            ${title}
                        </a>
                    </h3>
                    <p class="blog-text">
                        ${description}
                    </p>
                    <div class="blog-bottom">
                        <a href="${blogData.blogLink}" class="line-btn">
                            Read More
                            <i class="fas fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        `;
    };

    const generateBlogSliderHTML = (blogDataList) => {
        return blogDataList.map(generateBlogCardHTML);
    };

    const blogHTMLData = generateBlogSliderHTML(blogDataList);

//blog-end

// gallery-start
    const generateProjectCardHTML = (imageSrc) => {
        return `
        <div class="swiper-slide">
            <div class="project-box">
                <div class="project-img" style="width: 100%; height: 300px; overflow: hidden; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); border-radius: 10px;">
                    <img loading="lazy" src="${imageSrc}" alt="project image" style="width: 100%; height: 100%; object-fit: cover;">
                </div>
            </div>
        </div>
        `;
    };

    const projectImages = [
        "assets/img/gallery/warr/Warrgyizmorsch.pvt.ltd.jpeg",
        "assets/img/gallery/warr/Founder.jpg",
        "assets/img/gallery/warr/CEO.jpg",
        "assets/img/gallery/warr/Warrgyizmorsch Team.jpg",
        "assets/img/gallery/warr/img_1.jpeg",
        "assets/img/gallery/warr/img_2.jpeg",
        "assets/img/gallery/warr/img_3.jpeg",
        "assets/img/gallery/warr/img_4.jpeg",
        "assets/img/gallery/warr/img_5.jpeg",
        "assets/img/gallery/warr/img_6.jpeg",
        "assets/img/gallery/warr/img_7.jpeg",
        "assets/img/gallery/warr/img_8.jpeg",
        "assets/img/gallery/warr/img_9.jpeg",
        "assets/img/gallery/warr/img_10.jpeg"
    ];

    const generateProjectSliderHTML = (images) => {
        return images.map(generateProjectCardHTML);
    };

    const galleryHTMLData = generateProjectSliderHTML(projectImages);


//gallery-end
    
    return (
        <>
            <div className="th-hero-wrapper hero-5" id="hero">
                <div className="hero-inner">
                    <div className="th-hero-bg" data-bg-src="assets/img/hero/hero_first.webp"style={{ backgroundImage: `url('assets/img/hero/hero_first.webp')` }}></div>
                    <div className="container th-container5">
                        <div className="hero-style5" style={{ maxWidth: '100%', paddingTop: '80px', paddingBottom: '50px'}}>
                            <h1 className="hero-title text-white" style={{fontSize: '3rem'}}>Web Development <br />and Digital Marketing Company in the UK</h1>
                            <p className="hero-text text-white" >We are the leading Web Development Company in the UK. We provide the best solution for web development services tailored for businesses. We provide solutions to businesses of all sizes. Our professional expertise lies in the field of web design and digital marketing. We ensure a robust online presence to drive engagement and growth. We develop high-performing websites for your business at the best price. Developing an e-commerce web design to CMS-based sites, we handle everything just a click away.</p>
                            <div className="btn-group mt-35" >
                                <a href="/contact-us" className="th-btn style6 style-radius">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
            <div className="th-hero-wrapper hero-3" id="hero">
                <div className="hero-img">
                    <img src="assets/img/hero/hero_img_3_1.webp" alt="Hero" title="Hero Image" />
                    <div className="shape-blur"></div>
                </div>
                <div className="hero-img-phone">
                    <img src="assets/img/hero/hero_img_3_phone.webp" alt="Hero Phone" title="Hero Image Phone" />
                    <div className="shape-blur"></div>
                </div>
                <div className="container">
                    <div className="hero-style3">
                        <span className="hero-subtitle">WELCOME TO</span>
                        <h2 className="hero-title" style={{fontSize: '2.5rem'}}>Warrgyiz Morsch</h2>
                        <p className="hero-text">
                            Your number one source for all the outsourcing services. We're dedicated to providing you the very best of technical and non-technical services, with an emphasis on meeting the deadline, satisfactory service and 24*7 services. <br />
                            Founded in 2017, Warrgyizmorsch has come its way from its beginnings. When we first started out, our passion for our clients to flourish their business. <br />
                            We hope you enjoy our services as much as we enjoy offering them to you. If you have any questions or comments, please don't hesitate to contact us.
                        </p>
                        <div className="btn-group">
                            <a href="/about-us" className="th-btn">
                                ABOUT US
                                <i className="fa-regular fa-arrow-right ms-2"></i>
                            </a>
                            
                        </div>
                    </div>
                </div>
                
                <div className="hero-shape1">
                    <img src="assets/img/hero/hero_shape_3_1.png" alt="shape" title="shape1" />
                </div>
            </div>
            
            <section className="gr-bg3" id="service-sec">
                <div className="round-container space">
                    <div className="container">                        
                        <div className="title-area text-center">
                            {/* <span className="sub-title">OUR SERVICES</span> */}
                            <h2 className="sec-title">Why We Are the Top Choice for IT Solutions & Services in the UK</h2>
                        </div>
                        <div className="slider-area">
                            <ProductSlider cardData={cardHTMLDataForService} slidesPerView={3} />
                        </div>
                    </div>
                </div>
            </section>
            <div className="position-relative overflow-hidden" style={{ backgroundColor: '#f8f9fa'}}>
                <div className="container th-container5">
                    <div className="about-sec4 position-relative overflow-hidden" style={{ backgroundImage: `url('assets/img/normal/about_4.webp')` }}data-bg-src="">
                        <div className="about-shape4" style={{ borderRadius:'0px'}}>
                            <div className="marquee-wrapper">
                                <div className="marquee">
                                    <div className="marquee-group">
                                        <div className="text">Adaptability</div>
                                        <div className="text">Creative Innovation</div>
                                        <div className="text">4000+ partners</div>
                                        <div className="text">15M Customer Worldwide</div>
                                        <div className="text">Engagement</div>
                                        <div className="text">Ethical Practices</div>
                                        <div className="text">Communication</div>
                                        <div className="text">Personalization</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">50+ Countries</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">Innovation</div>
                                        <div className="text">Inclusivity</div>
                                        <div className="text">Diversity</div>
                                        <div className="text">Adaptability</div>
                                        <div className="text">Creative Innovation</div>
                                        <div className="text">4000+ partners</div>
                                        <div className="text">15M Customer Worldwide</div>
                                        <div className="text">Engagement</div>
                                        <div className="text">Ethical Practices</div>
                                        <div className="text">Communication</div>
                                        <div className="text">Personalization</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">50+ Countries</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">Innovation</div>
                                        <div className="text">Inclusivity</div>
                                        <div className="text">Diversity</div>
                                    </div>
                                    <div aria-hidden="true" className="marquee-group">
                                        <div className="text">Adaptability</div>
                                        <div className="text">Creative Innovation</div>
                                        <div className="text">4000+ partners</div>
                                        <div className="text">15M Customer Worldwide</div>
                                        <div className="text">Engagement</div>
                                        <div className="text">Ethical Practices</div>
                                        <div className="text">Communication</div>
                                        <div className="text">Personalization</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">50+ Countries</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">Innovation</div>
                                        <div className="text">Inclusivity</div>
                                        <div className="text">Diversity</div>
                                        <div className="text">Adaptability</div>
                                        <div className="text">Creative Innovation</div>
                                        <div className="text">4000+ partners</div>
                                        <div className="text">15M Customer Worldwide</div>
                                        <div className="text">Engagement</div>
                                        <div className="text">Ethical Practices</div>
                                        <div className="text">Communication</div>
                                        <div className="text">Personalization</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">50+ Countries</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">Innovation</div>
                                        <div className="text">Inclusivity</div>
                                        <div className="text">Diversity</div>
                                    </div>
                                </div>
                                <div className="marquee marquee--reverse">
                                    <div className="marquee-group">
                                        <div className="text">Adaptability</div>
                                        <div className="text">Creative Innovation</div>
                                        <div className="text">4000+ partners</div>
                                        <div className="text">15M Customer Worldwide</div>
                                        <div className="text">Engagement</div>
                                        <div className="text">Ethical Practices</div>
                                        <div className="text">Communication</div>
                                        <div className="text">Personalization</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">50+ Countries</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">Innovation</div>
                                        <div className="text">Inclusivity</div>
                                        <div className="text">Diversity</div>
                                        <div className="text">Adaptability</div>
                                        <div className="text">Creative Innovation</div>
                                        <div className="text">4000+ partners</div>
                                        <div className="text">15M Customer Worldwide</div>
                                        <div className="text">Engagement</div>
                                        <div className="text">Ethical Practices</div>
                                        <div className="text">Communication</div>
                                        <div className="text">Personalization</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">50+ Countries</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">Innovation</div>
                                        <div className="text">Inclusivity</div>
                                        <div className="text">Diversity</div>
                                    </div>
                                    <div aria-hidden="true" className="marquee-group">
                                        <div className="text">Adaptability</div>
                                        <div className="text">Creative Innovation</div>
                                        <div className="text">4000+ partners</div>
                                        <div className="text">15M Customer Worldwide</div>
                                        <div className="text">Engagement</div>
                                        <div className="text">Ethical Practices</div>
                                        <div className="text">Communication</div>
                                        <div className="text">Personalization</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">50+ Countries</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">Innovation</div>
                                        <div className="text">Inclusivity</div>
                                        <div className="text">Diversity</div>
                                        <div className="text">Adaptability</div>
                                        <div className="text">Creative Innovation</div>
                                        <div className="text">4000+ partners</div>
                                        <div className="text">15M Customer Worldwide</div>
                                        <div className="text">Engagement</div>
                                        <div className="text">Ethical Practices</div>
                                        <div className="text">Communication</div>
                                        <div className="text">Personalization</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">50+ Countries</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">Innovation</div>
                                        <div className="text">Inclusivity</div>
                                        <div className="text">Diversity</div>
                                    </div>
                                </div>
                                <div className="marquee">
                                    <div className="marquee-group">
                                        <div className="text">Adaptability</div>
                                        <div className="text">Creative Innovation</div>
                                        <div className="text">4000+ partners</div>
                                        <div className="text">15M Customer Worldwide</div>
                                        <div className="text">Engagement</div>
                                        <div className="text">Ethical Practices</div>
                                        <div className="text">Communication</div>
                                        <div className="text">Personalization</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">50+ Countries</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">Innovation</div>
                                        <div className="text">Inclusivity</div>
                                        <div className="text">Diversity</div>
                                        <div className="text">Adaptability</div>
                                        <div className="text">Creative Innovation</div>
                                        <div className="text">4000+ partners</div>
                                        <div className="text">15M Customer Worldwide</div>
                                        <div className="text">Engagement</div>
                                        <div className="text">Ethical Practices</div>
                                        <div className="text">Communication</div>
                                        <div className="text">Personalization</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">50+ Countries</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">Innovation</div>
                                        <div className="text">Inclusivity</div>
                                        <div className="text">Diversity</div>
                                    </div>
                                    <div aria-hidden="true" className="marquee-group">
                                        <div className="text">Adaptability</div>
                                        <div className="text">Creative Innovation</div>
                                        <div className="text">4000+ partners</div>
                                        <div className="text">15M Customer Worldwide</div>
                                        <div className="text">Engagement</div>
                                        <div className="text">Ethical Practices</div>
                                        <div className="text">Communication</div>
                                        <div className="text">Personalization</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">50+ Countries</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">Innovation</div>
                                        <div className="text">Inclusivity</div>
                                        <div className="text">Diversity</div>
                                        <div className="text">Adaptability</div>
                                        <div className="text">Creative Innovation</div>
                                        <div className="text">4000+ partners</div>
                                        <div className="text">15M Customer Worldwide</div>
                                        <div className="text">Engagement</div>
                                        <div className="text">Ethical Practices</div>
                                        <div className="text">Communication</div>
                                        <div className="text">Personalization</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">50+ Countries</div>
                                        <div className="text">Expertise</div>
                                        <div className="text">Innovation</div>
                                        <div className="text">Inclusivity</div>
                                        <div className="text">Diversity</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="about-area4">
                            <div className="title-area-wrapper">                                
                                <div className="slider-area p-4">
                                    <ProductSlider cardData={cardHTMLDataForAboutSlider} slidesPerView={1} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="container space">
                    <div className="row align-items-center flex-row-reverse">
                        <div className="col-lg-6 mb-30 mb-lg-0">
                            <div className="img-box7">
                                <div className="img1">
                                    <img loading="lazy" src="assets/img/normal/why_2_1.png" alt="Why" title="why_2" />
                                </div>
                                <div className="color-animate"></div>
                            </div>
                        </div>
                        <div className="col-lg-6 text-lg-start text-center">
                            <div className="title-area mb-35">
                                <h2 className="sec-title">Why Choose Warrgyizmorsch for Managed IT Solutions</h2>
                            </div>
                            <div className="two-column mb-40 list-center">
                                <div className="checklist style2">
                                    <ul>
                                        <li><img loading="lazy" src="assets/img/icon/check_1.png" alt="icon" /> Custom Solutions</li>
                                        <li><img loading="lazy" src="assets/img/icon/check_1.png" alt="icon" /> SEO & Performance Optimization</li>
                                    </ul>
                                </div>
                                <div className="checklist style2">
                                    <ul>
                                        <li><img loading="lazy" src="assets/img/icon/check_1.png" alt="icon" /> End-to-End Services</li>
                                    </ul>
                                </div>
                            </div>
                            <p className="mt-n2 mb-30">
                                Warrgyizmorsch offers some of the best solutions to users at affordable prices.
                                <br />
                                <strong>Custom Solution:</strong> We provide custom solutions to the business depending upon the need. We focus on fulfilling the goals and objectives of the business. 
                                <br />
                                <strong>SEO & Performance Optimization:</strong> Our expert team ensures that the website is fully optimized. We focus on speed, security, and SEO strategies, all aimed at improving the user experience. 
                                <br />
                                <strong>End-to-End Services:</strong> From designing to developing a marketing presence, we cover it all. We also offer continuous support and maintenance to mark the digital journey of the system.
                            </p>
                            
                            
                        </div>
                    </div>
                </div>
                <div className="shape-mockup" data-bottom="0" data-right="0">
                    <div className="particle-3" id="particle-4"></div>
                </div>
            </div>

            
            <div className="space" id="contact-sec" style={{ backgroundImage: `url('assets/img/bg/appointment_bg_1.jpg')` }}data-bg-src="">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 pe-xxl-5 mb-40 mb-xl-0">
                            <div className="title-area mb-35 text-xl-start text-center">                                
                                <h2 className="sec-title">
                                    Build Your Dream Website with <br />
                                    <span className="text-theme">Expert Developers</span>
                                </h2>
                            </div>
                            
                            <p className="mt-n2 mb-30 text-xl-start text-center">
                                Our expert develops high-quality websites with visually appealing appearances. Our team offers website development and custom web-design for businesses to achieve their goals. From e-commerce web-design to CMS-based site development. We ensure a scalable and optimized platform. We specialize in website development, offering customized solutions for better brand identity. With a high-performing and SEO-optimized platform. Our developers use the latest technologies to ensure a website that works fast, is scalable, and is user-friendly. 
                            </p>
                            <a href="/contact-us" className="th-btn">
                                CONTACT US<i className="fa-regular fa-arrow-right ms-2"></i>
                            </a>                           
                        </div>
                        <div className="col-xl-6 ps-xl-4">    
                            <h3 className="h4 mt-n2 mb-30 text-center">Enquiry Now</h3>
                            <EnquiryForm />
                        </div>
                    </div>
                </div>
            </div>

            <div className="overflow-hidden space" data-bg-src="assets/img/bg/choose_bg_2.jpg">
                <div className="container th-container5">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="title-area text-center">                                
                                <h2 className="sec-title text-black">Our Website Development</h2>
                            </div>
                        </div>
                    </div>
                    <div className="choose-tabs-wrapper">
                        <div className="nav nav-tabs choose-tabs-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-step1-tab" data-bs-toggle="tab" data-bs-target="#nav-step1" type="button" style={{ padding: '5px 10px', margin: '5px' }}>
                                Website Development
                            </button>
                            <button className="nav-link" id="nav-step2-tab" data-bs-toggle="tab" data-bs-target="#nav-step2" type="button" style={{ padding: '5px 10px', margin: '5px' }}>
                                Front-End Development
                            </button>
                            <button className="nav-link" id="nav-step3-tab" data-bs-toggle="tab" data-bs-target="#nav-step3" type="button" style={{ padding: '5px 10px', margin: '5px' }}>
                                Back-End Development
                            </button>
                            <button className="nav-link" id="nav-step4-tab" data-bs-toggle="tab" data-bs-target="#nav-step4" type="button" style={{ padding: '5px 10px', margin: '5px' }}>
                                Full-Stack Development
                            </button>
                            <button className="nav-link" id="nav-step5-tab" data-bs-toggle="tab" data-bs-target="#nav-step5" type="button" style={{ padding: '5px 10px', margin: '5px' }}>
                                Build Your Online Store
                            </button>
                            <button className="nav-link" id="nav-step6-tab" data-bs-toggle="tab" data-bs-target="#nav-step6" type="button" style={{ padding: '5px 10px', margin: '5px' }}>
                                Web Design & User Experience
                            </button>
                            <button className="nav-link" id="nav-step7-tab" data-bs-toggle="tab" data-bs-target="#nav-step7" type="button" style={{ padding: '5px 10px', margin: '5px' }}>
                                Content Management Systems (CMS)
                            </button>
                            <button className="nav-link" id="nav-step8-tab" data-bs-toggle="tab" data-bs-target="#nav-step8" type="button" style={{ padding: '5px 10px', margin: '5px' }}>
                                Web Application Development
                            </button>
                            <button className="nav-link" id="nav-step9-tab" data-bs-toggle="tab" data-bs-target="#nav-step9" type="button" style={{ padding: '5px 10px', margin: '5px' }}>
                                Website Maintenance & Support
                            </button>
                        </div>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-step1" role="tabpanel">
                                <div className="choose-wrapper">
                                    <div className="choose-content">
                                        <div className="title-area mb-30">
                                            <h5 className="sec-title mb-3 text-white">Website Development</h5>
                                            <p className="box-text text-white">We provide comprehensive web development services, focusing on the functionality and performance of the website. Our solution is to provide a mobile responsive, SEO optimised website to the users. Our website is simple, with an aesthetic look for the user. Talk to our expert in developing the best website for your business.</p>
                                        </div>
                                    </div>
                                    <div className="choose-image th-anim">
                                        <img loading="lazy" src="assets/img/normal/choose_img_1.jpg" alt="Website Development" />
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-step2" role="tabpanel">
                                <div className="choose-wrapper">
                                    <div className="choose-content">
                                        <div className="title-area mb-30">
                                            <h5 className="sec-title mb-3 text-white">Front-End Development</h5>
                                            <p className="box-text text-white">We develop interactive, fast, and responsive front-end interfaces for the users. Using a modern framework for the development. We craft visually appealing and user-interactive websites using reach, angular, nodejs, php, and vue.js. Our goal is to provide visitors to the site to improve engagement rate and higher chances of conversion rates.</p>
                                        </div>
                                    </div>
                                    <div className="choose-image th-anim">
                                        <img loading="lazy" src="assets/img/normal/choose_img_2.jpg" alt="Front-End Development" title="Front-End Development" />
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-step3" role="tabpanel">
                                <div className="choose-wrapper">
                                    <div className="choose-content">
                                        <div className="title-area mb-30">
                                            <h5 className="sec-title mb-3 text-white">Back-End Development</h5>
                                            <p className="box-text text-white">Providing secure and scalable back-end development for flawless functionality and integration. Our expert back-end development team offers security to the database, API, and server-side functionality. The robust infrastructure supports the front-end experience. It is a more reliable, efficient website for your business.</p>
                                        </div>
                                    </div>
                                    <div className="choose-image th-anim">
                                        <img loading="lazy" src="assets/img/normal/choose_img_3.jpg" alt="Back-End Development" title="Back-End Development" />
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-step4" role="tabpanel">
                                <div className="choose-wrapper">
                                    <div className="choose-content">
                                        <div className="title-area mb-30">
                                            <h5 className="sec-title mb-3 text-white">Full-Stack Development</h5>
                                            <p className="box-text text-white">A complete development solution for both front-end and back-end technologies. By integrating both the technologies in the system. Our full-stack development services offer end-to-end solutions for the needs of the business.  The comprehensive approach ensures a smooth digital experience for the users.</p>
                                        </div>
                                    </div>
                                    <div className="choose-image th-anim">
                                        <img loading="lazy" src="assets/img/normal/choose_img_4.jpg" alt="Full-Stack Development" title="Full-Stack Development" />
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-step5" role="tabpanel">
                                <div className="choose-wrapper">
                                    <div className="choose-content">
                                        <div className="title-area mb-30">
                                            <h5 className="sec-title mb-3 text-white">Build Your Online Store</h5>
                                            <p className="box-text text-white">By developing the scalable and user-friendly online store for the business. Our professional experts develop the best design for your online store. The experts ensure the smooth functioning and integration of apps. Like the payment gateway, product catalogs, and the website. It helps to increase sales in the store. Our custom-made solution helps to provide a smooth shopping experience to the user. It helps in increasing the revenue with the conversions. It increases the revenue of the business with the best content writing management system.</p>
                                        </div>
                                    </div>
                                    <div className="choose-image th-anim">
                                        <img loading="lazy" src="assets/img/icon/Online Store.avif" alt="Online Store Development" title="Online Store Development"/>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-step6" role="tabpanel">
                                <div className="choose-wrapper">
                                    <div className="choose-content">
                                        <div className="title-area mb-30">
                                            <h5 className="sec-title mb-3 text-white">Web Design & User Experience</h5>
                                            <p className="box-text text-white">Our web design services are mainly focused on functionality, usability, and customer engagement. User experience is the main element of the web design services. Our expert develops aesthetics website which focused on conversions, engagement and to identify brand identity. It helps to connect with new target audiences for improving effective online presence.</p>
                                        </div>
                                    </div>
                                    <div className="choose-image th-anim">
                                        <img loading="lazy" src="assets/img/icon/website.avif" alt="Web Design & UX" title="Web Design & UX" />
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-step7" role="tabpanel">
                                <div className="choose-wrapper">
                                    <div className="choose-content">
                                        <div className="title-area mb-30">
                                            <h5 className="sec-title mb-3 text-white">Content Management Systems (CMS)</h5>
                                            <p className="box-text text-white">We offer a user-friendly CMS platform like WordPress and Shopify for easy content update and management. Our team develops CMS based on platforms like WordPress, Shopify, and Magento. It enables easy management and updating of the content on the website. It maintains the accuracy of the content without requiring any technical knowledge.</p>
                                        </div>
                                    </div>
                                    <div className="choose-image th-anim">
                                        <img loading="lazy" src="assets/img/icon/cms.jpg" alt="CMS Development" title="CMS Development" />
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-step8" role="tabpanel">
                                <div className="choose-wrapper">
                                    <div className="choose-content">
                                        <div className="title-area mb-30">
                                            <h5 className="sec-title mb-3 text-white">Web Application Development</h5>
                                            <p className="box-text text-white">Custom-tailored web app designs for the scalability and efficiency of the website. Our professional team develops custom-built web applications for improving automation, customer interactions, and operational efficiency. Our application is to develop solutions to the challenges. To achieve the goals of the business and to provide effective solutions.</p>
                                        </div>
                                    </div>
                                    <div className="choose-image th-anim">
                                        <img loading="lazy" src="assets/img/icon/mobile app.jpg" alt="Web Application Development" title="Web Application Development" />
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="nav-step9" role="tabpanel">
                                <div className="choose-wrapper">
                                    <div className="choose-content">
                                        <div className="title-area mb-30">
                                            <h5 className="sec-title mb-3 text-white">Website Maintenance & Support</h5>
                                            <p className="box-text text-white">Ongoing website monitoring and security updates improving performance optimization. We offer solutions for the digital success of your business beyond the development process. Our experts offer support, security updates, performance monitoring, and troubleshooting. They ensure the website remains secure and up-to-date and performs optimizations all the time.</p>
                                        </div>
                                    </div>
                                    <div className="choose-image th-anim">
                                        <img loading="lazy" src="assets/img/icon/software-code.avif" alt="Website Maintenance & Support" title="Website Maintenance & Support" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="counter-area-4 overflow-hidden space-bottom">                
                <div className="container th-container5">
                    <h2 className='text-center pb-4'>Warrgyizmorsch' Digital Marketing Services</h2>
                    <div className="counter-card4-wrap">
                        <div className="counter-card4">                            
                            <div className="media-body">
                                <h3 className="">Search Engine Optimization (SEO)</h3>
                                <p className="counter-text mb-0">
                                    Boost your website with advanced ranking and best SEO strategies to improve website ranking. Our strategy is for both off-page and on-page techniques. It helps in improving the website's search engine ranking. It helps in driving organic traffic to the website to improve website visibility. And to attract potential customers for seeking the products or the services. 
                                </p>
                            </div>
                        </div>
                        <div className="divider"></div>
                        <div className="counter-card4">                            
                            <div className="media-body">
                                <h3 className="">Technical <br />SEO</h3>
                                <p className="counter-text mb-0">
                                    To enhance the performance of the website with structured data, mobile optimization, and speed improvement. Our experts optimize the site structure, speed, and responsiveness of the website. It also helps in enhancing the performance of the website. They assure that SEO techniques can crawl the website easily. So that it improves the ranking and user experience. 
                                </p>
                            </div>
                        </div>
                        <div className="divider"></div>
                        <div className="counter-card4">                            
                            <div className="media-body">
                                <h3 className="">Custom Website Design</h3>
                                <p className="counter-text mb-0">
                                    With the best custom website development for the businesses to improve branding. Our custom website design services focus on the unique site for better functionalities and user-friendliness of the website. This helps in designing the website with different approaches of the business for this competitive online landscape.
                                </p>
                            </div>
                        </div>
                        <div className="divider"></div>
                        
                    </div>
                    <hr />
                    <div className="counter-card4-wrap">
                        <div className="counter-card4">                            
                            <div className="media-body">
                                <h3 className="">eCommerce Marketing</h3>
                                <p className="counter-text mb-0">
                                    E-commerce marketing helps in driving sales with a given target audience. Our e-commerce marketing strategies include PPC advertising, SEO, email marketing, and retargeting campaigns. This business is designed to maximize sales and enhance the customer experience to improve business growth. 
                                </p>
                            </div>
                        </div>
                        <div className="divider"></div>
                        <div className="counter-card4">                            
                            <div className="media-body">
                                <h3 className="">Content <br />Writing</h3>
                                <p className="counter-text mb-0">
                                    High-quality content for the website, blogs, articles, and marketing materials. We provide optimized informative content for use. Well-designed content helps in grabbing new customers. Also helps in engaging the audience to the website. Content is an important part of improving the search engine ranking. 
                                </p>
                            </div>
                        </div>
                        <div className="divider"></div>
                        <div className="counter-card4">                            
                            <div className="media-body">
                                <h3 className="">Social Media Marketing</h3>
                                <p className="counter-text mb-0">
                                    To improve brand awareness and engagement using social media marketing. Developed the campaign strategy for the social media account. With the aim of brand awareness, engagement, and lead generation. Choosing the platform where the audience is most active and easy to catch for meaningful interactions. 
                                </p>
                            </div>
                        </div>
                        <div className="divider"></div>
                        
                    </div>
                    
                </div>
            </div>        

            <section className="overflow-hidden space" style={{ backgroundImage: `url('assets/img/bg/contact_bg_1.webp')` }} data-bg-src="" data-overlay="overlay1" data-opacity="5">
                <div className="container z-index-common">
                    <div className="row align-items-center">
                        <div className="col-xl-5">
                            <div className="pe-xxl-5 text-xl-start text-center">
                                <div className="title-area mb-35">
                                    <span className="sub-title">                                        
                                        TESTIMONIALS
                                    </span>
                                    <h2 className="sec-title text-white">
                                        Let's Here What Are The Customer 
                                        <span className="text-theme"> Saying</span>
                                    </h2>
                                </div>
                                <p className="mt-n2 mb-35 text-white">
                                    Our clients trust us for the quality, innovation and customer satisfaction. Read the success stories of the clients provided below to see the transformation in the businesses. 
                                </p>
                                <a href="/about-us" className="th-btn style3 shadow-none">
                                    VIEW MORE
                                    <i className="fa-regular fa-arrow-right ms-2"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-7 mt-40 mt-xl-0">
                            <div className="testi-card-area slider-area">                                
                                <div className="testi-thumb-wrap">
                                    <div className="testi-thumb testi-card-tab" data-slider-tab="#testiSlide1">
                                        <div className="tab-btn active">
                                            <img loading="lazy" src="assets/img/testimonial/testi_1_1.jpg" alt="Image1" title="Image1" />
                                        </div>
                                        <div className="tab-btn">
                                            <img loading="lazy" src="assets/img/testimonial/testi_1_2.jpg" alt="Image2" title="Image2" />
                                        </div>
                                        <div className="tab-btn">
                                            <img loading="lazy" src="assets/img/testimonial/testi_1_3.jpg" alt="Image3" title="Image3" />
                                        </div>
                                        <div className="tab-btn">
                                            <img loading="lazy" src="assets/img/testimonial/testi_1_4.jpg" alt="Image4" title="Image4" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-wrapper">
                                    <ProductSlider cardData={cardHTMLDataForTestimonials} slidesPerView={1} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            
            <section className="overflow-hidden bg-white space" data-overlay="overlay1" data-opacity="2">
                <div className="container th-container5">
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="title-area mb-50 text-center text-md-start">
                                <span className="sub-title">
                                    Stories
                                </span>
                                <h2 className="sec-title">Our work that inspired you to work with us</h2>
                            </div>
                            <div className="project-btn">
                                <a href="/gallery" className="th-btn style-border style-radius">View All</a>
                            </div>
                        </div>
                        <div className="col-xl-8">
                            <div className="slider-area project-slider4">                                
                                <div className="swiper-wrapper">
                                    <ProductSlider cardData={galleryHTMLData} slidesPerView={2} />
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="bg-smoke overflow-hidden space" id="faq-sec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="title-area text-center text-xl-start">                                
                                <h2 className="sec-title">
                                    Custom Web Development Services: <span className="text-theme fw-normal">Frequently Asked Question?</span>
                                </h2>
                            </div>
                            <div className="accordion-area accordion" id="faqAccordion">
                                {/* FAQ 1 */}
                                <div className="accordion-card">
                                    <div className="accordion-header" id="collapse-item-1">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse-1"
                                            aria-expanded="false"
                                            aria-controls="collapse-1"
                                        >
                                            What is a Web Development Service?  
                                        </button>
                                    </div>
                                    <div
                                        id="collapse-1"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="collapse-item-1"
                                        data-bs-parent="#faqAccordion"
                                    >
                                        <div className="accordion-body">
                                            <p className="faq-text">
                                                Web Development Services offers services like designing, building, and maintaining the website for the businesses or the individual business. It ensures that the website is fully functioning, visually appealing, and optimized for performance. Developing a simple complex web application. It helps businesses in recognising the business online. 
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* FAQ 2 */}
                                <div className="accordion-card">
                                    <div className="accordion-header" id="collapse-item-2">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse-2"
                                            aria-expanded="false"
                                            aria-controls="collapse-2"
                                        >
                                            What's the cost of your Software Development Services?
                                        </button>
                                    </div>
                                    <div
                                        id="collapse-2"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="collapse-item-2"
                                        data-bs-parent="#faqAccordion"
                                    >
                                        <div className="accordion-body">
                                            <p className="faq-text">
                                                The cost of the software services is based on the complexity of the project. LIke the features and the timeline of the projects. The cost of the software depends on factors like project complexity, features, technologies used, and timeline. Contact us for the personalised quote based on the requirement. Higher functionality with best price availability. 
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* FAQ 3 */}
                                <div className="accordion-card">
                                    <div className="accordion-header" id="collapse-item-3">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse-3"
                                            aria-expanded="false"
                                            aria-controls="collapse-3"
                                        >
                                            What do web development services include?
                                        </button>
                                    </div>
                                    <div
                                        id="collapse-3"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="collapse-item-3"
                                        data-bs-parent="#faqAccordion"
                                    >
                                        <div className="accordion-body">
                                            <p className="faq-text">
                                                Web development services include custom solutions, front-end development, back-end development, e-commerce solutions, and content management system (CMS) development.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* FAQ 4 */}
                                <div className="accordion-card">
                                    <div className="accordion-header" id="collapse-item-4">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse-4"
                                            aria-expanded="false"
                                            aria-controls="collapse-4"
                                        >
                                            How can I optimize a web app for SEO?
                                        </button>
                                    </div>
                                    <div
                                        id="collapse-4"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="collapse-item-4"
                                        data-bs-parent="#faqAccordion"
                                    >
                                        <div className="accordion-body">
                                            <p className="faq-text">
                                                To improve and optimized the search engine visibility of your web application, consider the following:
                                            </p>
                                            <ul>
                                                <li>Optimize site speed for faster load times.</li>
                                                <li>Ensure mobile responsiveness to provide a seamless experience on all devices.</li>
                                                <li>Use metadata optimization for better indexing by search engines.</li>
                                                <li>Implement structured data (schema markup) to enhance search rankings.</li>
                                                <li>Improve internal linking and URL structure for better navigation.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* FAQ 5 */}
                                <div className="accordion-card">
                                    <div className="accordion-header" id="collapse-item-5">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse-5"
                                            aria-expanded="false"
                                            aria-controls="collapse-5"
                                        >
                                            What web development services does Warrgyizmorsch offer?
                                        </button>
                                    </div>
                                    <div
                                        id="collapse-5"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="collapse-item-5"
                                        data-bs-parent="#faqAccordion"
                                    >
                                        <div className="accordion-body">
                                            <p className="faq-text">
                                                Warrgyizmorsch offers custom web development, eCommerce web design, CMS solutions, SEO optimization, and custom software development. We assure the best quality of the projects before time.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* FAQ 6 */}
                                <div className="accordion-card">
                                    <div className="accordion-header" id="collapse-item-6">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse-6"
                                            aria-expanded="false"
                                            aria-controls="collapse-6"
                                        >
                                            How is the web development team structured?
                                        </button>
                                    </div>
                                    <div
                                        id="collapse-6"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="collapse-item-6"
                                        data-bs-parent="#faqAccordion"
                                    >
                                        <div className="accordion-body">
                                            <p className="faq-text">
                                                Our web development team consists of:
                                            </p>
                                            <ul>
                                                <li>Front-End Developers – Experts in creating visually appealing interfaces.</li>
                                                <li>Back-End Developers – Skilled in databases, APIs, and server management.</li>
                                                <li>UI/UX Designers – Ensuring intuitive user experiences.</li>
                                                <li>SEO Specialists – Enhancing website visibility and rankings.</li>
                                                <li>Content Strategists & Writers – Creating engaging and optimized content.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* FAQ 7 */}
                                <div className="accordion-card">
                                    <div className="accordion-header" id="collapse-item-7">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapse-7"
                                            aria-expanded="false"
                                            aria-controls="collapse-7"
                                        >
                                            How do you control the quality of the software you deliver?
                                        </button>
                                    </div>
                                    <div
                                        id="collapse-7"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="collapse-item-7"
                                        data-bs-parent="#faqAccordion"
                                    >
                                        <div className="accordion-body">
                                            <p className="faq-text">
                                                We follow a top-quality assurance process, which includes:
                                            </p>
                                            <ul>
                                                <li>Agile Development Practices – Ensuring flexibility and efficiency.</li>
                                                <li>Continuous Testing – Conducting real-time testing throughout development.</li>
                                                <li>Performance Optimization – Enhancing speed, security, and usability.</li>
                                                <li>Bug Fixing & Debugging – Identifying and resolving potential issues.</li>
                                                <li>Post-Launch Support – Ongoing updates and maintenance for long-term success.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
            <section className="bg-top-right overflow-hidden space" id="blog-sec" style={{ backgroundImage: `url('assets/img/bg/blog_bg_1.png')` }} data-bg-src="" data-overlay="overlay1" data-opacity="1">
                <div className="container">
                    <div className="title-area text-center">
                        <span className="sub-title">
                            LATEST BLOGS
                        </span>
                        <h2 className="sec-title">
                            Get Every Single Update 
                            <span className="text-theme fw-normal"> Blog</span>
                        </h2>
                    </div>
                    <div className="slider-area">
                        <ProductSlider cardData={blogHTMLData} slidesPerView={3} />                        
                    </div>
                </div>
                
            </section>
            


        </>
    )
}

export default Test;